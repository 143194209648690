import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '', redirectTo: 'inicio', pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'declaracionjurada',
    loadChildren: () => import('./declaracionjurada/declaracionjurada.module').then( m => m.DeclaracionjuradaPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'pruebateorica',
    loadChildren: () => import('./pruebateorica/pruebateorica.module').then( m => m.PruebateoricaPageModule)
  },
  {
    path: 'pruebapsiquica',
    loadChildren: () => import('./pruebapsiquica/pruebapsiquica.module').then( m => m.PruebapsiquicaPageModule)
  },
  {
    path: 'pruebapractica',
    loadChildren: () => import('./pruebapractica/pruebapractica.module').then( m => m.PruebapracticaPageModule)
  },
  {
    path: 'colores',
    loadChildren: () => import('./psiquicas/colores/colores.module').then( m => m.ColoresPageModule)
  },
  {
    path: 'direcciones',
    loadChildren: () => import('./psiquicas/direcciones/direcciones.module').then( m => m.DireccionesPageModule)
  },
  {
    path: 'memorizanumeros',
    loadChildren: () => import('./psiquicas/memorizanumeros/memorizanumeros.module').then( m => m.MemorizanumerosPageModule)
  },
  {
    path: 'grannumero',
    loadChildren: () => import('./psiquicas/grannumero/grannumero.module').then( m => m.GrannumeroPageModule)
  },
  {
    path: 'ordenfiguras',
    loadChildren: () => import('./psiquicas/ordenfiguras/ordenfiguras.module').then( m => m.OrdenfigurasPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
